<template>
  <v-container>
    <vue-snotify></vue-snotify>

    <v-row align="center" class="pt-10 pb-10" justify="center">
      <v-col cols="12" md="4" sm="4">
        <v-row>
          <v-col cols="12" md="12">
            <div class="text-center primary">
              <h3 class="text-center white--text pb-3 pt-3">Iniciar sesión</h3>
            </div>
          </v-col>
        </v-row>
        <v-card class="">
          <v-row>
            <v-col cols="12" md="12">
              <v-card-text>
                <div style="text-align: center">
                  <br />
                  <v-btn class="ma-2" outlined x-large fab color="primary">
                    <v-icon color="primary">mdi-account-lock-open</v-icon>
                  </v-btn>
                  <br />
                  <br />
                </div>
                <v-form class="pl-5 pr-5">
                  <v-text-field
                    label="Correo"
                    v-model="email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                    color="cyan darken-1"
                  />
                  <v-text-field
                    label="Contraseña"
                    v-model="password"
                    name="password"
                    prepend-icon="mdi-form-textbox-password"
                    type="password"
                    color="cyan darken-1"
                  />
                </v-form>
              </v-card-text>
              <div class="text-center mb-5 pl-2">
                <v-btn
                  color="primary"
                  elevation="3"
                  width="85%"
                  class="mr-4"
                  @click="create"
                  outlined
                >
                  <v-icon>mdi-login</v-icon>Ingresar
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12" md="12">
                  <div class="text-center text--blue mb-1">
                    <h5>Si olvidaste tus credenciales por favor contáctanos</h5>
                  </div>

                  <v-divider></v-divider>
                  <div class="text-center mt-1 text--blue">
                    <h5>¿No tienes una cuenta?</h5>
                  </div>
                </v-col>
              </v-row>
              <div class="text-center mt-1 mb-2">
                <v-btn
                  color="primary"
                  elevation="3"
                  width="85%"
                  to="/crear-cliente"
                >
                  <v-icon>mdi-home-plus</v-icon>
                  <span>Regístrate aquí</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import auth from "@/logic/auth";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    email: "",
    password: "",
    name: "",
    description: "",
    menu1: false,
    user: "",
    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
    nameRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
  }),

  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "home" }), 10);
    },
    getUserByToken(token) {
      console.log("entra obtener usuario por token");
      let request = {
        token: token,
      };
      axios
        .post("/register", request)
        .then((response) => {
          // this.displayNotification("success", "Éxito", "ya logueo");
          let user = [
            token,
            response.data.name,
            response.data.rut,
            response.data.user_type,
            response.data.id,
          ];
          console.log(user);
          auth.setUserLogged(user);
          console.log(response.data);

          if (response.data.user_type === "admin") {
            this.$router.push({ name: "Layout" });
          } else if (response.data.user_type === "user") {
            // alert("entra aqui");
            /*  let request = { rut: auth.getUserRut() };
            axios
              .post("/customer-by-rut", request)
              .then((response) => {
                console.log(response);
                //this.displayNotification("success", "Éxito", "ya logueo");
              })
              .catch((err) => {
                console.log(err, "error");
                this.displayNotification(
                  "error",
                  "Error",
                  "Credenciales incorrectas"
                );
              });*/
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Credenciales incorrectas"
          );
        });
    },
    create() {
 
      let token = "";
      let request = {
        email: this.email,
        password: this.password,
      };
      console.log("imprime credenciales");
      console.log(this.email);
      console.log(this.password);
      axios
        .post("/login", request)
        .then((response) => {
          //this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          token = response.data;
          console.log("entra a imprimir tipo usuario");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", err.message);
          setTimeout(() => this.$router.push({ name: "Layout" }), 6000);
        })
        .finally(() => {
          this.getUserByToken(token);
          //setTimeout(() => this.$router.push({ name: "Layout" }), 6000);
        });
    },
  },
};
</script>